<!-- 短信模板变量配置页面（新增、编辑、删除） -->
<template>
  <div>
    <a-modal
      title="短信模板变量配置"
      width="60%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      @cancel="handleModalCancel"
    >
      <div class="divAreaCss">
        模板名称：<span style="color: red">{{ templateInfo.tplName }}</span>
        <br />
        模板内容：<span style="color: red">{{ templateInfo.tplContent }}</span>
      </div>
      <div class="divAreaCss">
        <a-card title="模板变量列表">
          <div class="tool">
            <div>
              <a-button
                type="primary"
                @click="handleAdd"
                style="margin-bottom: 8px"
                :disabled="addBtnDisabled"
              >
                <PlusOutlined />添加变量
              </a-button>
            </div>
          </div>
          <div>
            <a-table
              class="ant-table-striped"
              size="middle"
              bordered
              :data-source="templateItemList"
              :columns="columns"
              :pagination="false"
              :rowKey="(record) => record.id"
            >
              <template
                v-for="col in ['tplItem', 'itemSort']"
                #[col]="{ text, record }"
                :key="col"
              >
                <div>
                  <a-input
                    v-if="editableData[record.id]"
                    v-model:value="editableData[record.id][col]"
                    style="margin: -5px 0"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template #operation="{ record }">
                <div>
                  <span v-if="editableData[record.id]">
                    <a-button type="link" @click.stop="handleSave(record.id)"
                      ><SaveOutlined />保存</a-button
                    >
                    <a-popconfirm
                      title="您确定要取消编辑吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleCancel(record.id)"
                    >
                      <a-button type="link"><UndoOutlined />取消</a-button>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a-button type="link" @click.stop="handleEdit(record.id)"
                      ><EditOutlined />编辑</a-button
                    >
                    <a-popconfirm
                      title="您确信要执行删除吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleDelete(record.id)"
                    >
                      <a-button type="link"><DeleteOutlined />删除</a-button>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
            <div class="pagination">
              <a-pagination
                :current="pagination.pageIndex"
                :pageSize="pagination.pageSize"
                :total="pagination.total"
                :page-size-options="pagination.pageSizeOptions"
                :showTotal="(total) => `共 ${total} 条记录`"
                show-size-changer
                @change="onChangePage"
                @showSizeChange="onShowSizeChange"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
          </div>
        </a-card>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button @click="handleModalCancel"><UndoOutlined />取消</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { cloneDeep } from "lodash-es";
import { reactive, toRefs, onMounted, defineComponent, watch } from "vue";
import {
  getTemplateItemListPage,
  saveTemplateItem,
  deleteTemplateItem,
} from "@/api/message/templateitem";
import SvgIcon from "@/components/Svgicon";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "TemplateItem",
  components: {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined,
    SvgIcon,
    UndoOutlined,
  },
  // 接受父组件传递的值
  props: {
    itemModalVisible: {
      type: Boolean,
      default: false,
    },
    currentInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const state = reactive({
      loading: false,
      addBtnDisabled: false,
      visible: false,
      templateItemList: [],
      templateInfo: {
        tplId: null,
        tplName: "",
        tplContent: "",
      },
      editableData: {},
      searchParam: {
        tplId: null,
        pageIndex: 1,
        pageSize: 5,
      },
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
      columns: [
        {
          title: "变量名称",
          dataIndex: "tplItem",
          width: "30%",
          slots: {
            customRender: "tplItem",
          },
        },
        {
          title: "变量位置",
          dataIndex: "itemSort",
          width: "30%",
          align: "center",
          slots: {
            customRender: "itemSort",
          },
          sorter: (a, b) => {
            if (a.itemSort < b.itemSort) {
              return -1;
            }
            if (a.itemSort > b.itemSort) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          slots: {
            customRender: "operation",
          },
        },
      ],
    });
    onMounted(() => {
      initTemplateItemList(state.searchParam);
    });
    const initTemplateItemList = (searchParam) => {
      getTemplateItemListPage(searchParam).then((resp) => {
        if (resp) {
          state.templateItemList = resp.obj.list;
          console.log(state.templateItemList);
        }
      });
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initTemplateItemList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initTemplateItemList(state.searchParam);
    };

    const handleAdd = () => {
      state.addBtnDisabled = true;
      const newData = {
        id: 9999,
        tplId: state.templateInfo.tplId,
        tplItem: "",
        itemSort: null,
      };
      state.templateItemList.push(newData);
      console.log(state.templateItemList);
      state.editableData[9999] = cloneDeep(
        state.templateItemList.filter((item) => item.id === 9999)[0]
      );
      console.log(state.editableData);
    };

    const handleEdit = (key) => {
      state.addBtnDisabled = true;
      state.editableData[key] = cloneDeep(
        state.templateItemList.filter((item) => key === item.id)[0]
      );
    };

    const handleCancel = (key) => {
      state.addBtnDisabled = false;
      delete state.editableData[key];
      if (key === 9999) {
        state.templateItemList = state.templateItemList.filter(
          (item) => item.id != 9999
        );
      }
    };

    const handleSave = (key) => {
      if (state.editableData[key]["tplItem"] == "") {
        message.error("短信模板变量不能为空！！！");
      } else if (state.editableData[key]["itemSort"] == null) {
        message.error("短信模板变量位置不能为空！！！");
      } else {
        saveTemplateItem(state.editableData[key]).then((resp) => {
          if (resp.code == 200) {
            Object.assign(
              state.templateItemList.filter((item) => key === item.id)[0],
              state.editableData[key]
            );
            let total = state.pagination.total + 1;
            let lastPage = Math.ceil(total / state.pagination.pageSize);
            state.pagination.pageIndex = lastPage;
            state.searchParam.pageIndex = lastPage;
            initTemplateItemList(state.searchParam);
            state.addBtnDisabled = false;
            delete state.editableData[key];
          }
        });
      }
    };

    const handleDelete = (key) => {
      deleteTemplateItem({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination.total - 1;
          let lastPage = Math.ceil(total / state.pagination.pageSize);
          if (lastPage < state.pagination.pageIndex) {
            state.pagination.pageIndex = lastPage;
            state.searchParam.pageIndex = lastPage;
          }
          initTemplateItemList(state.searchParam);
        }
      });
    };

    const handleModalCancel = () => {
      context.emit("handleItemModalVisible", false);
      state.templateInfo = {};
    };

    const refData = toRefs(state);

    watch(
      () => [props.currentInfo, props.itemModalVisible],
      ([nv1, nv2]) => {
        state.visible = nv2;
        state.templateInfo = nv1;
        state.searchParam.tplId = nv1.tplId;
        if (nv1.tplId) {
          initTemplateItemList(state.searchParam);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      ...refData,
      onChangePage,
      onShowSizeChange,
      handleModalCancel,
      handleAdd,
      handleEdit,
      handleSave,
      handleCancel,
      handleDelete,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.ant-card-body {
  padding: 10px;
}
.divAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>