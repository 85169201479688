import { getRequest, postRequest, putRequest, deleteRequest } from '../request'

export const getTemplateItemList = (res) => {
    return getRequest('/template/items', res)
}

export const getTemplateItemListPage = (res) => {
    return postRequest('/template/items', res)
}

export const saveTemplateItem = (res) => {
    if (res.id === 9999) {
        //添加短信模板
        return postRequest('/template/item', res);
    }
    else {
        //更新短信模板
        return putRequest('/template/item', res);
    }
}

export const deleteTemplateItem = (res) => {
    return deleteRequest('/template/item/' + res.id + "?logic=" + res.logic)
}